import env from '@/config/env'
import { createAxiosInstance } from '@/api/http'
import type { VaixAnalyticsParams } from '@/types/api/events.ts'
import { HEADERS } from '@/utilities/constants.ts'

const http = createAxiosInstance(
  {
    baseURL: env.vaix.url
  },
  [HEADERS.Authorization, HEADERS.VaixClientID],
  {
    isVaix: true
  }
)

const sendVaixAnalytics = async (params: VaixAnalyticsParams) => {
  const url = 'api/tracker/events'

  await http.post(url, params, {
    withCredentials: false
  })
}

export default { sendVaixAnalytics }
