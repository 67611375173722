import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore, storeToRefs } from 'pinia'
import { Client } from '@stomp/stompjs'

import useNotificationsStore from '@/stores/notifications'
import useTranslationsStore from '@/stores/translations'
import useAuthStore from '@/stores/auth'
import useAuth from '@/composables/useAuth'
import { ROUTES } from '@/utilities/constants'
import config from '@/config'
import env from '@/config/env'
import {
  STOMP_SESSION_MESSAGES,
  STOMP_TERMINAL_MESSAGES,
  type StompSessionMessageData,
  type StompTerminalMessageData
} from '@/types/stores/stompClient'

const useStompClient = defineStore('stomp-client', () => {
  const router = useRouter()
  const notificationsStore = useNotificationsStore()
  const translationsStore = useTranslationsStore()
  const authStore = useAuthStore()
  const auth = useAuth()

  const { isCashoutLoading, isAuthenticated, isTerminalOutOfOrder } = storeToRefs(authStore)

  const { addNotification } = notificationsStore
  const { t } = translationsStore

  let client: Client | null = null

  const isClientActivated = ref(false)
  const currentSession = ref<string | null>(null)

  const handleStompSessionMessage = async (body: StompSessionMessageData) => {
    switch (body.type) {
      case STOMP_SESSION_MESSAGES.SESSION_INITIATED:
        await router.push({
          name: ROUTES.OTP,
          params: {
            sessionId: currentSession.value
          }
        })
        break

      /**
       * TERMINAL_DEACTIVATED and SESSION_TERMINATED are always sent at the same time.
       *
       * When calling /cashout api, SESSION_TERMINATED can come during or after api call.
       */
      case STOMP_SESSION_MESSAGES.SESSION_TERMINATED:
        if (isCashoutLoading.value || !isAuthenticated.value) return

        await auth.handlePostLogoutActions()
        break

      case STOMP_SESSION_MESSAGES.DEPOSIT_SUCCESSFUL:
        addNotification(t('successful_deposit_notification_message'), 'success')
        break

      case STOMP_SESSION_MESSAGES.DEPOSIT_FAILED:
        addNotification(t('deposit_failed_notification_info'), 'error')
        break
    }
  }

  const handleStompTerminalMessage = async (body: StompTerminalMessageData) => {
    switch (body.type) {
      case STOMP_TERMINAL_MESSAGES.TERMINAL_ACTIVATED:
        await auth.initQRCodeAndStomp()
        break

      case STOMP_TERMINAL_MESSAGES.TERMINAL_DEACTIVATED:
        isTerminalOutOfOrder.value = true
        await router.push({ name: ROUTES.QR_CODE })
        break
    }
  }

  const subscribeSession = (sessionId: string) => {
    currentSession.value = sessionId

    client?.subscribe(`/topic/session/${sessionId}`, async (message) => {
      const body: StompSessionMessageData = JSON.parse(message.body)
      await handleStompSessionMessage(body)
    })
  }

  const unsubscribeSession = (sessionId: string) => {
    currentSession.value = null

    client?.unsubscribe(`/topic/session/${sessionId}`)
  }

  const subscribeTerminal = () => {
    client?.subscribe(`/topic/terminal/${config.dongleId}`, async (message) => {
      const body: StompTerminalMessageData = JSON.parse(message.body)
      await handleStompTerminalMessage(body)
    })
  }

  const init = (sessionId: string | undefined) => {
    client = new Client({
      brokerURL: env.ssbtStompUrl,
      reconnectDelay: 3000,
      debug: function (str) {
        console.log(str)
      }
    })

    client.onConnect = () => {
      const validSessionId = currentSession.value || sessionId
      if (validSessionId) subscribeSession(validSessionId)
      subscribeTerminal()
      isClientActivated.value = true
    }

    client.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers['message'])
      console.error('Additional details: ' + frame.body)
      console.error('Error', frame)
    }

    client.activate()
  }

  return {
    currentSession,
    isClientActivated,
    init,
    subscribeSession,
    unsubscribeSession
  }
})

export default useStompClient
