import { ref } from 'vue'
import { defineStore } from 'pinia'

const initialModalState = {
  loginRequired: false,
  logout: false
}

const useModalStore = defineStore('modal', () => {
  const modalState = ref({ ...initialModalState })

  const closeAllModals = () => {
    modalState.value = { ...initialModalState }
  }

  return {
    modalState,
    closeAllModals
  }
})

export default useModalStore
