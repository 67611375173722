export enum SLAVE_EVENT {
  GameOpen = 'Game.Open',
  GameClose = 'Game.Close',
  Click = 'click'
}

export enum GATEWAY_EVENT {
  SlaveEvent = 'Slave.Event',
  AnalyticsSend = 'Analytics.Send',
  DocumentChangeMeta = 'Document.ChangeMeta',
  DocumentChangeTitle = 'Document.ChangeTitle',
  RouterRouteChanged = 'Router.RouteChanged',
  UserLoginRequired = 'User.LoginRequired'
}

export enum GATEWAY_EMIT_EVENT {
  UserAuthorizationChanged = 'User.AuthorizationChanged',
  UserBalanceChanged = 'User.BalanceChanged',
  RouteChanged = 'Router.RouteChanged'
}

interface BaseSlaveEvent {
  action: string
  msgSender: string
  plugin: string
  slaveId: string
}

interface ClickEvent extends BaseSlaveEvent {
  event: SLAVE_EVENT.Click
}

interface GameEvent extends BaseSlaveEvent {
  event: SLAVE_EVENT.GameOpen | SLAVE_EVENT.GameClose
  data: {
    game: Game
  }
}

interface Game {
  code: string
  demo: boolean
  id: number
  isLive: boolean
  isVaixEvent: boolean
  page: string
  position: number
  productDisplayId: string
  providerName: string
  swimLane: string
  title: string
}

export interface NotifyRouteChangedParams {
  params: {
    param1: 'all'
    param2: string
  }
}

interface AnalyticsContent {
  id: string
  value: {
    gameIds: string[]
    page: string
  }
}

export interface AnalyticsSendEvent extends BaseSlaveEvent {
  data: {
    content: AnalyticsContent[]
    params: {
      category: string
    }
    type: 'VaixImpressions'
  }
}

export type SlaveEventMessage = ClickEvent | GameEvent
