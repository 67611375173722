import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { storeToRefs } from 'pinia'

import useAuthStore from '@/stores/auth'
import { HEADERS } from '@/utilities/constants'
import env from '@/config/env'

type AdditionalOptions = Partial<{
  withBasicAuth: boolean
  isVaix: boolean
}>

function createAxiosInstance(
  options: AxiosRequestConfig,
  additionalHeaders?: HEADERS[],
  additionalOptions?: AdditionalOptions
): AxiosInstance {
  const instance = axios.create(options)

  instance.interceptors.request.use((config) => {
    const authStore = useAuthStore()

    const { authData } = storeToRefs(authStore)

    const headersMapper: Record<HEADERS, string> = {
      [HEADERS.CompanyUUID]: env.tenant.uuid,
      [HEADERS.Authorization]: `Bearer ${additionalOptions?.isVaix ? env.vaix.token : authData.value?.jwt}`,
      [HEADERS.RequestID]: crypto.randomUUID(),
      [HEADERS.VaixClientID]: env.vaix.clientId
    }

    config.headers[HEADERS.RequestID] = headersMapper[HEADERS.RequestID]

    additionalHeaders?.forEach((header) => {
      config.headers[header] = headersMapper[header]
    })

    if (additionalOptions?.withBasicAuth) {
      config.auth = env.basicAuth
    }

    return config
  })

  return instance
}

export { createAxiosInstance }
