export const enum STOMP_SESSION_MESSAGES {
  SESSION_TERMINATED = 'SESSION_TERMINATED',
  SESSION_INITIATED = 'SESSION_INITIATED',
  DEPOSIT_SUCCESSFUL = 'DEPOSIT_SUCCESSFUL',
  DEPOSIT_FAILED = 'DEPOSIT_FAILED'
}

export const enum STOMP_TERMINAL_MESSAGES {
  TERMINAL_ACTIVATED = 'TERMINAL_ACTIVATED',
  TERMINAL_DEACTIVATED = 'TERMINAL_DEACTIVATED'
}

interface BaseMessage {
  timestamp: string
}

interface BaseTerminalMessage extends BaseMessage {
  terminalId: string
}

interface BaseSessionMessage extends BaseMessage {
  sessionId: string
}

interface TerminalMessageBody {
  type: STOMP_TERMINAL_MESSAGES
  message: BaseTerminalMessage
}

interface SessionMessageBody {
  type: Exclude<STOMP_SESSION_MESSAGES, STOMP_SESSION_MESSAGES.DEPOSIT_SUCCESSFUL>
  message: BaseSessionMessage
}

interface SuccessfulDepositMessageBody {
  type: STOMP_SESSION_MESSAGES.DEPOSIT_SUCCESSFUL
  message: BaseSessionMessage & {
    amount: number
  }
}

export type StompSessionMessageData = SessionMessageBody | SuccessfulDepositMessageBody
export type StompTerminalMessageData = TerminalMessageBody
