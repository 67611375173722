import { ref } from 'vue'
import { defineStore } from 'pinia'
import { type ToastNotifications, type NotificationVariant } from '@nsftx/seven-components'

import { getApiErrorMessage } from '@/utilities/error.ts'

const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<ToastNotifications>([])

  const addNotification = (
    message: string,
    variant: NotificationVariant = 'error',
    uuid = crypto.randomUUID()
  ) => {
    const duplicateNotification = notifications.value.find((notification) =>
      notification.messages?.includes(message)
    )
    if (duplicateNotification) return

    notifications.value.push({
      messages: [message],
      variant,
      uuid
    })
  }

  const handleErrorNotification = (err: unknown) => {
    addNotification(getApiErrorMessage(err))
  }

  return {
    notifications,
    addNotification,
    handleErrorNotification
  }
})

export default useNotificationsStore
