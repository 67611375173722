import { storeToRefs } from 'pinia'

import useModalStore from '@/stores/modal.ts'
import useGravityGatewayStore from '@/stores/gravityGateway.ts'
import useAuthStore from '@/stores/auth.ts'
import { type SSBTButtonEventDetail, SSBTButtons } from '@/types/events.ts'

const useSSBTEventMonitor = () => {
  const gravityGatewayStore = useGravityGatewayStore()
  const modalStore = useModalStore()
  const authStore = useAuthStore()

  const { notifyRouteChanged } = gravityGatewayStore
  const { closeAllModals } = modalStore

  const { modalState } = storeToRefs(modalStore)
  const { isAuthenticated } = storeToRefs(authStore)

  const initCustomEventListener = () => {
    window.addEventListener('adpkeyevent', (event: CustomEvent<SSBTButtonEventDetail>) => {
      switch (event.detail.keydown) {
        case SSBTButtons.Menu:
          closeAllModals()
          notifyRouteChanged({
            params: {
              param1: 'all',
              param2: ''
            }
          })
          break
        case SSBTButtons.Cashout:
          if (!isAuthenticated.value) return

          modalState.value.logout = true
          break
      }
    })
  }

  const start = () => {
    initCustomEventListener()
  }

  return {
    start
  }
}

export default useSSBTEventMonitor
